// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"

import "trix"
import "@rails/actiontext"
// require("trix")
// require("@rails/actiontext")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// External imports
import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";


// Internal imports, e.g:
import { initFlickity } from '../plugins/init_flickity';
import { initLazyLoading } from '../plugins/init_lazyloading';
import { initToggleAbout } from '../plugins/init_toggle_about';
// import { initColorthief } from '../plugins/init_colorthief';
// import { initCopyLink } from '../plugins/init_copy_link';

document.addEventListener('turbolinks:load', () => {
  initFlickity();
  initToggleAbout();
  // initColorthief();
  initLazyLoading();
  // initCopyLink();
});




