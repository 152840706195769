import { Controller } from "@hotwired/stimulus"
import { csrfToken } from "@rails/ujs";
import ColorThief from '../packs/color_thief'


export default class extends Controller {
  static targets = [ "image", "colorBox" ]
  static values = { url: String }

  connect() {
    this.load()
  }


  load() {
    const colorThief = new ColorThief();
    const image = this.imageTarget;
    const colorBox = this.colorBoxTarget;
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = image.src;

    let url = this.urlValue

    const colorFetch = (mainColor) => {
      let formData = new FormData
      formData.append("main_color", mainColor);
      fetch(url, {
        method: 'POST',
        headers: { 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
        body: formData
      })
    }

    if (img.complete) {
      let mainColor = `rgba(${colorThief.getColor(img)[0]}, ${colorThief.getColor(img)[1]}, ${colorThief.getColor(img)[2]}, 0.4)`
      colorFetch(mainColor)
      colorBox.style.backgroundColor = mainColor

    } else {
      img.addEventListener('load', function () {
        let mainColor = `rgba(${colorThief.getColor(img)[0]}, ${colorThief.getColor(img)[1]}, ${colorThief.getColor(img)[2]}, 0.4)`
        colorFetch(mainColor);
        colorBox.style.backgroundColor = mainColor
      });
    }
  }
}
