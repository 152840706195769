import { Controller } from "@hotwired/stimulus"
import { csrfToken, preventInsignificantClick } from "@rails/ujs";

export default class extends Controller {
  static targets = ["artwork"]
  static values = { url: String }

  connect() {
  }

  delete() {
    this.artworkTarget.remove()
  }
}
