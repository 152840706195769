import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "slide"]

  connect() {
    // console.log(this.buttonTargets)
    // console.log(this.slideTargets)
  }

  visibility(event) {
    if (event.srcElement.nextElementSibling.classList.contains('is-visible')) {
      event.srcElement.nextElementSibling.classList.remove('is-visible')
    } else {
      this.slideTargets.forEach(element => {
        element.classList.remove('is-visible')
      });
      event.srcElement.nextElementSibling.classList.add('is-visible')
    }
  }
}
