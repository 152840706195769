const initLazyLoading = () => {
  if (document.querySelector('.is_this_art')) {
    const flickSlider = document.querySelector('.is_this_art');
    const allImages = flickSlider.querySelectorAll('.carousel-cell');
    const buttonNext = document.querySelector('.next');
    const buttonPrevious = document.querySelector('.previous');

    function lazyLoad() {
      allImages.forEach(elem => {
        if (elem.classList.contains('is-selected')) {
          if (elem.nextElementSibling) {
            if (elem.nextElementSibling.querySelector('img').src != elem.nextElementSibling.dataset.imgSrc) {
              elem.nextElementSibling.querySelector('img').src = elem.nextElementSibling.dataset.imgSrc
            }
          }
          if (elem.previousElementSibling) {
            if (elem.previousElementSibling.querySelector('img').src != elem.previousElementSibling.dataset.imgSrc) {
              elem.previousElementSibling.querySelector('img').src = elem.previousElementSibling.dataset.imgSrc
            }
          } else {
            if (allImages[allImages.length - 1].querySelector('img').src != allImages[allImages.length - 1].dataset.imgSrc) {
              allImages[allImages.length - 1].querySelector('img').src = allImages[allImages.length - 1].dataset.imgSrc
            }
          }
        }
      });
    }

    // events when to load next image
    // TODO : find name of the swipe event on mobile
    buttonNext.addEventListener('click', lazyLoad);
    buttonPrevious.addEventListener('click', lazyLoad);
    allImages.forEach(elem => {
      elem.addEventListener('click', lazyLoad)
    })
    flickSlider.addEventListener('touchmove', lazyLoad);
    document.addEventListener('keydown', function (e) {
      if(e.key == "ArrowLeft" || e.key == "ArrowRight") { lazyLoad() }
    });
  }
}

export { initLazyLoading };
